import { Flex } from '@chakra-ui/react';
import { ColumnFormatType, DayOfWeek } from 'common/enums';
import { DimensionConfig, MetricConfigWithId } from 'common/types';
import DnDElements from 'src/blueprint/components/DnDElements/DnDElements';
import FormTitle from '../../layout/FormTitle';
import { useCallback, useMemo } from 'react';

export interface DimensionValue {
    id: string;
    value: string | number;
    label: string;
    formatType: ColumnFormatType;
    customDisplayName?: string | null;
    customDecimalPlaces?: number | null;
    weekStart?: DayOfWeek | null;
}

interface SelectDimensionsProps {
    selectedDimensions?: DimensionConfig[];
    dimensionOptions?: DimensionConfig[];
    isSingle?: boolean;
    onChange: (values: DimensionValue[]) => void;
}

const dndElementStyle = {
    style: {
        backgroundColor: '#C8E6C9'
    },
    hoverStyle: {
        backgroundColor: '#A5D6A7'
    }
};

const SelectDimensions = ({
    selectedDimensions,
    dimensionOptions,
    onChange,
    isSingle
}: SelectDimensionsProps) => {
    const values = useMemo(() => {
        return (
            selectedDimensions?.map((ds) => {
                return {
                    id: ds.id,
                    label: ds.defaultDisplayName,
                    value: ds.id,
                    formatType: ds.formatType,
                    customDisplayName: ds.customDisplayName,
                    customDecimalPlaces: ds.customDecimalPlaces,
                    weekStart: ds.weekStart
                };
            }) ?? []
        );
    }, [selectedDimensions]);

    const handleChange = useCallback(
        (values: DimensionValue[]) => {
            if (isSingle) {
                const values_ = values?.[values.length - 1] ?? [];

                onChange?.([values_]);
            } else {
                onChange?.(values);
            }
        },
        [onChange, isSingle]
    );

    const options = useMemo(() => {
        return (
            dimensionOptions?.map((ds: MetricConfigWithId) => ({
                id: ds.id,
                label: ds.defaultDisplayName,
                value: ds.id,
                formatType: ds.formatType,
                customDisplayName: ds.customDisplayName,
                customDecimalPlaces: ds.customDecimalPlaces,
                weekStart: ds.weekStart
            })) ?? []
        );
    }, [dimensionOptions]);

    const memoizedChildren = useMemo(
        () => (
            <>
                <Flex flexDir={'column'}>
                    <DnDElements.Values />
                </Flex>
                <DnDElements.Options triggerButtonText="Add dimension" />
            </>
        ),
        []
    );

    return (
        <Flex w="100%" flexDir="column">
            <FormTitle title="Select dimensions" />
            <DnDElements
                values={values}
                options={options}
                onValuesChange={handleChange}
                elementStyle={dndElementStyle}
            >
                {memoizedChildren}
            </DnDElements>
        </Flex>
    );
};

export default SelectDimensions;
