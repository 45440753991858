import { Box, Flex, useTheme } from '@chakra-ui/react';
import React from 'react';
import { DateRange, Range, RangeFocus } from 'react-date-range';
import { ActivePeriod, IDateRanges } from './DatePicker2';
import { format } from 'date-fns';
import { sk } from 'date-fns/locale';
import DateRangeInputs from './DateRangeInputs';
import DateRangeWrapper from './DateRangeWrapper';

interface CustomDateRangePickerProps {
    value: IDateRanges;
    rangeColors?: string[];
    focusedRange: RangeFocus;
    setFocusedRange: (range: RangeFocus) => void;
    onChange: (range: IDateRanges) => void;
    minDate?: Date;
    maxDate?: Date;
    properties?: any;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
    value,
    rangeColors,
    focusedRange,
    setFocusedRange,
    onChange,
    minDate,
    maxDate
}) => {
    const theme = useTheme();

    const handleChange = (range: IDateRanges) => {
        onChange(range);
    };

    const currentActivePeriod =
        focusedRange[0] === 0 ? ActivePeriod.DEFAULT : ActivePeriod.COMPARE;

    return (
        <Flex flexDir="column" justifyContent="center" p="1rem" py="1.25rem">
            <DateRangeInputs
                value={value}
                onChange={(item) => handleChange({ ...value, ...item })}
                focusedRange={focusedRange}
                setFocusedRange={setFocusedRange}
                rangeColors={rangeColors}
            />
            <DateRangeWrapper currentActivePeriod={currentActivePeriod}>
                <DateRange
                    locale={sk}
                    rangeColors={[
                        theme.colors.datePicker.primaryRange[200],
                        theme.colors.datePicker.secondaryRange[200]
                    ]}
                    focusedRange={focusedRange}
                    onRangeFocusChange={(focusedRange_) => {
                        setFocusedRange([focusedRange[0], focusedRange_[1]]);
                    }}
                    ranges={[value.default, value.compare].filter(Boolean) as Range[]}
                    onChange={(item) => {
                        handleChange({
                            ...value,
                            ...item
                        });
                    }}
                    showDateDisplay={false}
                    moveRangeOnFirstSelection={false}
                    dayContentRenderer={(day) => (
                        <Box
                            as="span"
                            data-testid={`date-picker-day-${format(day, 'd.M.yyyy')}`}
                            style={{
                                fontWeight: '400',
                                fontSize: '14px'
                            }}
                        >
                            {format(day, 'd')}
                        </Box>
                    )}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </DateRangeWrapper>
        </Flex>
    );
};

export default CustomDateRangePicker;
