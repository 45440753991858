import {
    Box,
    Button,
    Fade,
    Flex,
    FormControl,
    FormLabel,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Switch,
    Text,
    useOutsideClick
} from '@chakra-ui/react';

import { RangeFocus, StaticRange } from 'react-date-range';

import { CalendarIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { styleValidator } from 'common/validators';
import {
    addDays,
    areIntervalsOverlapping,
    differenceInDays,
    endOfYesterday
} from 'date-fns';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { z } from 'zod';
import CustomDateRangePicker from './CustomDateRangePicker';
import { staticRanges } from './staticRanges';
import { getEnv } from 'src/utils';
import { assertIsDefined } from '../../utils';

interface IDateRange {
    startDate: Date;
    endDate: Date;
    key: string;
}

export enum ActiveDateType {
    START = 'start',
    END = 'end'
}

export enum ActivePeriod {
    DEFAULT = 'default',
    COMPARE = 'compare'
}

export enum ComparePeriod {
    PREVIOUS_PERIOD = 'previous_period',
    PREVIOUS_YEAR = 'previous_year',
    EXACT = 'exact'
}

export interface IDateRanges {
    [ActivePeriod.DEFAULT]: IDateRange;
    [ActivePeriod.COMPARE]?: IDateRange;
}

interface Props extends z.infer<typeof styleValidator.dateRange> {
    value: IDateRanges;
    onChange: (internalValue: IDateRanges) => void;
    minDate?: EpochTimeStamp;
    maxDate?: EpochTimeStamp;
}

const env = getEnv();

const isProductionEnv = ['mds', 'production'].includes(env);

interface ExtendedRange extends StaticRange {
    isDisabled?: boolean;
}

export default function DatePicker2({
    onChange,
    value,
    properties,
    minDate,
    maxDate
}: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useRef(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [focusedRange, setFocusedRange] = useState<RangeFocus>([0, 0]);

    const currentActivePeriod =
        focusedRange[0] === 0 ? ActivePeriod.DEFAULT : ActivePeriod.COMPARE;

    let minimumDate = new Date();
    let maximumDate = new Date();
    if (minDate && maxDate) {
        minimumDate = new Date(minDate);
        maximumDate = new Date(maxDate);
        if (maximumDate < minimumDate) {
            minimumDate = addDays(new Date(), -7);
            maximumDate = endOfYesterday();
        }
    }
    if (!minDate && maxDate) {
        maximumDate = endOfYesterday();
        minimumDate = new Date(0);
    }
    if (minDate && !maxDate) {
        minimumDate = new Date(minDate ?? 0);
        maximumDate = endOfYesterday();
    }

    const [internalValue, setInternalValue] = useState<IDateRanges>({
        default: {
            startDate: value?.default.startDate ?? addDays(new Date(), -7),
            endDate: value?.default.endDate ?? endOfYesterday(),
            key: 'default'
        },
        ...(value?.compare
            ? {
                  compare: {
                      startDate: value?.compare.startDate ?? addDays(new Date(), -14),
                      endDate: value?.compare.endDate ?? addDays(new Date(), -7),
                      key: 'compare'
                  }
              }
            : {})
    });

    const [previousInternalValue, setPreviousInternalValue] =
        useState<IDateRanges>(internalValue);

    const onInternalValueChange = (
        internalValue_: IDateRanges,
        isStaticPeriod?: boolean
    ) => {
        if (
            focusedRange[0] === 1 &&
            (isStaticPeriod || focusedRange[1] === 1) &&
            isComparePeriod
        ) {
            const isOverlapping = areIntervalsOverlapping(
                {
                    start: internalValue_.default.startDate,
                    end: internalValue_.default.endDate
                },
                {
                    start: internalValue_.compare?.startDate ?? new Date(),
                    end: internalValue_.compare?.endDate ?? new Date()
                },
                { inclusive: true }
            );

            if (isOverlapping) {
                setErrorMessage('Date ranges cannot overlap.');
                setInternalValue(previousInternalValue);
                return;
            }
        }

        if (
            focusedRange[0] === 0 &&
            (isStaticPeriod || focusedRange[1] === 1) &&
            isComparePeriod
        ) {
            const dayDiff = differenceInDays(
                internalValue_.default.endDate,
                internalValue_.default.startDate
            );

            internalValue_.compare = {
                startDate: addDays(internalValue_?.default?.startDate, -dayDiff - 1),
                endDate: addDays(internalValue_?.default?.startDate, -1),
                key: 'compare'
            };
        }

        if (focusedRange[1] === 1) {
            setPreviousInternalValue(internalValue_);
        }

        setErrorMessage(null);

        setInternalValue(internalValue_);
    };

    // compare period switch
    const [isComparePeriod, setIsComparePeriod] = useState<boolean>(
        internalValue?.compare ? true : false
    );

    const handleSetComparePeriod = (comparePeriod: boolean) => {
        setIsComparePeriod(comparePeriod);
        if (comparePeriod) {
            const dayDiff = differenceInDays(
                internalValue.default.endDate,
                internalValue.default.startDate
            );
            setInternalValue({
                default: internalValue.default,
                compare: {
                    startDate: addDays(internalValue?.default?.startDate, -dayDiff - 1),
                    endDate: addDays(internalValue?.default?.startDate, -1),
                    key: 'compare'
                }
            });
        } else {
            setFocusedRange([0, 0]);
            setInternalValue({
                default: internalValue?.default
            });
        }
    };

    const value_ = internalValue;

    const startDateString = DateTime.fromJSDate(value_.default.startDate).toFormat(
        'dd.MM.yyyy'
    );
    const endDateString = DateTime.fromJSDate(value_.default.endDate).toFormat(
        'dd.MM.yyyy'
    );
    const compareStartDateString = value_?.compare
        ? DateTime.fromJSDate(value_.compare.startDate).toFormat('dd.MM.yyyy')
        : undefined;
    const compareEndDateString = value_?.compare
        ? DateTime.fromJSDate(value_.compare.endDate).toFormat('dd.MM.yyyy')
        : undefined;

    const ranges: ExtendedRange[] = useMemo(() => {
        const min = addDays(new Date(minimumDate ?? '2023-01-01'), -1);
        const max = maximumDate ? new Date(maximumDate) : endOfYesterday();

        if (!minDate && !maxDate) {
            return staticRanges;
        }
        const ranges = staticRanges.map((r) => {
            const range = r.range();
            const rangeStart = range.startDate;
            const rangeEnd = range.endDate;

            assertIsDefined(rangeStart, 'Range start is not defined');
            assertIsDefined(rangeEnd, 'Range end is not defined');

            if (
                rangeStart.getTime() >= min.getTime() &&
                rangeStart.getTime() <= max.getTime() &&
                rangeEnd.getTime() <= max.getTime()
            ) {
                return r;
            } else {
                return {
                    ...r,
                    isDisabled: true
                };
            }
        });

        return ranges;
    }, [minimumDate, maximumDate]);

    const open = () => {
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    const handleClickOutside = (e: Event) => {
        if (buttonRef.current?.contains(e.target as Node)) {
            return;
        }

        close();
    };

    useOutsideClick({
        ref: popoverRef,
        handler: handleClickOutside
    });

    return (
        <Popover
            placement="bottom-end"
            returnFocusOnClose={false}
            isOpen={isOpen}
            onOpen={open}
            onClose={close}
        >
            {({ onClose }) => (
                <>
                    <PopoverTrigger>
                        <Button
                            ref={buttonRef}
                            leftIcon={
                                properties?.showCalendarIcon ? (
                                    <CalendarIcon style={properties?.iconStyle} />
                                ) : undefined
                            }
                            rightIcon={
                                properties?.showDropdownIndicator ? (
                                    <ChevronDownIcon h={6} w={6} />
                                ) : undefined
                            }
                            height="100%"
                            variant="datepicker"
                            data-testid="date-picker-button"
                            sx={properties?.buttonStyle}
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        >
                            <Flex flexDir="column" alignItems="start">
                                {properties?.text
                                    ? properties.text
                                    : `${startDateString} - ${endDateString}`}
                                {!properties?.text &&
                                    compareStartDateString &&
                                    compareEndDateString && (
                                        <Text fontSize="xs" fontWeight={300} mt="5px">
                                            {`vs. ${compareStartDateString} - ${compareEndDateString}`}
                                        </Text>
                                    )}
                            </Flex>
                        </Button>
                    </PopoverTrigger>
                    <Portal>
                        <Box zIndex={80000} position="relative" ref={popoverRef}>
                            <PopoverContent
                                w="auto"
                                data-testid="date-picker-popover"
                                position="relative"
                                borderRadius="20px"
                                border="1px solid #E2E8F0"
                            >
                                <Flex>
                                    <Flex
                                        borderRight="1px solid #D9D9D9"
                                        py="1.5rem"
                                        flexDir="column"
                                        justifyContent="space-between"
                                    >
                                        <Flex
                                            flexDir="column"
                                            pl="2rem"
                                            pr="3rem"
                                            maxH="18rem"
                                            gap="0.5rem"
                                            overflowY="auto"
                                        >
                                            {ranges.map((range) => {
                                                const isActive =
                                                    internalValue[
                                                        currentActivePeriod
                                                    ]?.startDate.getTime() ===
                                                        range
                                                            .range()
                                                            .startDate?.getTime() &&
                                                    internalValue[
                                                        currentActivePeriod
                                                    ]?.endDate.getTime() ===
                                                        range.range().endDate?.getTime();

                                                const activeColor =
                                                    currentActivePeriod ===
                                                    ActivePeriod.DEFAULT
                                                        ? 'datePicker.primaryRange.100'
                                                        : 'datePicker.secondaryRange.100';

                                                return (
                                                    <Button
                                                        key={range.label}
                                                        variant="unstyled"
                                                        textAlign="left"
                                                        fontFamily="Inter"
                                                        fontWeight="400"
                                                        fontSize="15px"
                                                        isDisabled={range.isDisabled}
                                                        color={
                                                            isActive
                                                                ? activeColor
                                                                : 'datePicker.gray.300'
                                                        }
                                                        backgroundColor="transparent !important"
                                                        outline="none !important"
                                                        border="none !important"
                                                        _hover={{
                                                            color: 'datePicker.primaryRange.100'
                                                        }}
                                                        onClick={() => {
                                                            onInternalValueChange(
                                                                {
                                                                    ...internalValue,
                                                                    [currentActivePeriod]:
                                                                        {
                                                                            startDate:
                                                                                range.range()
                                                                                    .startDate as Date,
                                                                            endDate:
                                                                                range.range()
                                                                                    .endDate as Date,
                                                                            key: currentActivePeriod
                                                                        }
                                                                },
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        {range.label}
                                                    </Button>
                                                );
                                            })}
                                        </Flex>
                                        <Box pl="2rem" pr="3rem" pt="2rem">
                                            {!isProductionEnv && (
                                                <FormControl
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <FormLabel
                                                        htmlFor="compare-periods"
                                                        mb="0"
                                                    >
                                                        Compare
                                                    </FormLabel>
                                                    <Switch
                                                        id="compare-periods"
                                                        colorScheme="green"
                                                        onChange={() =>
                                                            handleSetComparePeriod(
                                                                !isComparePeriod
                                                            )
                                                        }
                                                        isChecked={isComparePeriod}
                                                    />
                                                </FormControl>
                                            )}
                                        </Box>
                                    </Flex>

                                    <Flex flexDir="column" justifyContent="space-between">
                                        <CustomDateRangePicker
                                            properties={properties}
                                            onChange={(item) =>
                                                onInternalValueChange(item)
                                            }
                                            value={internalValue}
                                            focusedRange={focusedRange}
                                            setFocusedRange={setFocusedRange}
                                            rangeColors={properties?.rangeColors}
                                            minDate={
                                                minDate
                                                    ? new Date(
                                                          minimumDate ?? '2023-01-01'
                                                      )
                                                    : undefined
                                            }
                                            maxDate={
                                                maxDate
                                                    ? maximumDate
                                                        ? new Date(maximumDate)
                                                        : endOfYesterday()
                                                    : undefined
                                            }
                                        />
                                        <Flex
                                            justifyContent="space-between"
                                            alignItems="flex-end"
                                            px="1rem"
                                            pb="1rem"
                                        >
                                            <Fade in={Boolean(errorMessage)}>
                                                <Text fontSize="0.75rem" color="red.500">
                                                    {errorMessage}
                                                </Text>
                                            </Fade>

                                            <Button
                                                variant="solid"
                                                backgroundColor="datePicker.primaryRange.100"
                                                color="white"
                                                size="xs"
                                                _hover={{
                                                    backgroundColor:
                                                        'datePicker.primaryRange.100',
                                                    color: 'white',
                                                    borderColor:
                                                        'datePicker.primaryRange.100',
                                                    opacity: 0.9
                                                }}
                                                fontFamily="Inter"
                                                onClick={() => {
                                                    onChange(internalValue);
                                                    onClose();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </PopoverContent>
                        </Box>
                    </Portal>
                </>
            )}
        </Popover>
    );
}
