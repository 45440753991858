import { Box, Flex, Text, chakra } from '@chakra-ui/react';
import { NavLink as _ReactRouterNavLink } from 'react-router-dom';

import { useAppSelector } from '@hooks';
import { ComponentUI } from '@types';

import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { selectActiveReport } from 'src/redux/features/blueprint/bluePrintSlice';
import { HorizontalAlign, ReportPage, VerticalAlign } from 'common/types';
import { useMemo } from 'react';

const DEFAULT_BUTTON_STYLE = {
    display: 'flex'
};

const ReactRouterNavLink = chakra(_ReactRouterNavLink);

export default function Navbar({ properties }: ComponentUI) {
    const activeReport = useAppSelector(selectActiveReport);
    const { state } = useCanvas();
    const inEditor = state.useEditorReport;

    const buttonStyle = {
        ...DEFAULT_BUTTON_STYLE,
        ...properties.buttonStyle
    };

    const { _active: activeStyle, ...restButtonStyle } = buttonStyle;

    const activeButtonStyle = {
        ...restButtonStyle,
        ...(activeStyle ?? {})
    };

    const pages = useMemo(() => {
        if (Array.isArray(activeReport?.pageOrder)) {
            const pagesFormPageOrder =
                activeReport.pageOrder
                    .map((pageId) =>
                        activeReport.pages.find((page) => page.id === pageId)
                    )
                    .filter((page): page is ReportPage => Boolean(page?.isActive)) ?? [];
            const leftOverPages =
                activeReport.pages
                    ?.filter((page) => activeReport.pageOrder?.indexOf(page.id) === -1)
                    .filter((page): page is ReportPage => Boolean(page?.isActive)) ?? [];
            return [...pagesFormPageOrder, ...leftOverPages];
        } else {
            return (
                activeReport?.pages.filter((page): page is ReportPage =>
                    Boolean(page?.isActive)
                ) ?? []
            );
        }
    }, [activeReport?.pageOrder, activeReport?.pages]);

    const justifyContentMap: Record<HorizontalAlign, string> = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end'
    };

    const justifyContent =
        justifyContentMap?.[
            properties.buttonContainer?.horizontalAlign as HorizontalAlign
        ] ?? 'center';

    const alignItemsMap: Record<VerticalAlign, string> = {
        top: 'flex-start',
        middle: 'center',
        bottom: 'flex-end'
    };

    const alignItems =
        alignItemsMap?.[properties.buttonContainer?.verticalAlign as VerticalAlign] ??
        'center';

    const buttonWrapperStyle = {
        width: '100%',
        height: '100%',
        display: 'flex',
        ...properties.buttonContainer,
        textAlign: properties.buttonContainer?.horizontalAlign ?? 'center',
        justifyContent: justifyContent,
        alignItems: alignItems
    };

    const activeButtonWrapperStyle = {
        ...buttonWrapperStyle,
        ...properties.buttonContainer?._active
    };

    return (
        <Box style={properties.style}>
            <Flex
                flexDir="column"
                h="100%"
                maxH="100%"
                overflow="hidden"
                style={properties.containerStyle}
                {...(inEditor ? { pointerEvents: 'none' } : {})}
            >
                {pages.map((page) => {
                    const to = `${state.reportBasePathName}/${activeReport?.slug}/${page?.slug}`;

                    return (
                        <ReactRouterNavLink
                            role="group"
                            to={to}
                            style={(props) =>
                                props.isActive ? activeButtonWrapperStyle : {}
                            }
                            key={page.id}
                            sx={buttonWrapperStyle}
                        >
                            {({ isActive }) => (
                                <Text
                                    sx={isActive ? activeButtonStyle : restButtonStyle}
                                    _groupHover={restButtonStyle._hover}
                                >
                                    {page.displayName}
                                </Text>
                            )}
                        </ReactRouterNavLink>
                    );
                })}
            </Flex>
        </Box>
    );
}
