import { Box, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { defaultTheme } from "common/constants";
import { styleValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import ColorForm from "../../forms/ColorForm";
import DropShadowForm from "../../forms/DropShadow";
import FormTextInput from "../../forms/inputs/Text";
import PalletForm from "../../forms/PalletForm";
import PositionForm from "../../forms/PositionForm";
import StyleDivider from "../../StyleDivider";
import TextForm from "../../forms/TextForm";

type FormData = z.infer<typeof styleValidator.dateRange>

const DateRangeStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultTheme.components.DATE_RANGE,
        values: componentToEdit as any,
        resolver: zodResolver(styleValidator.dateRange),
        mode: 'onBlur',
    })

    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <StyleDivider />
                <FormAccordion>
                    <FormAccordionItem title="Component styles">
                        <TextForm<FormData> objPath={'properties.labelStyle'} textTitle="Label" textPath="properties.label" />
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Control">
                        <Flex flexDir='column'>
                            <FormTextInput name='properties.text' title='Placeholder' />
                            <ColorForm<FormData> title='Color' name={"properties.buttonStyle.color"} />
                            <ColorForm<FormData> title='Background Color' name={"properties.buttonStyle.backgroundColor"} />
                            <ColorForm<FormData> title='Border Color' name={"properties.buttonStyle.borderColor"} />
                            <ColorForm<FormData> title='Hover Color' name={"properties.buttonStyle._hover.color"} />
                            <ColorForm<FormData> title='Hover Background Color' name={"properties.buttonStyle._hover.backgroundColor"} />
                            <DropShadowForm<FormData> objPath="properties.buttonStyle" />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Picker Style">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.pickerStyle.color'} />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Omit Picker Style">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.omitPickerStyle.color'} />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Submit Button Style">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Color' name={"properties.submitButtonStyle.color"} />
                            <ColorForm<FormData> title='Background Color' name={"properties.submitButtonStyle.backgroundColor"} />
                            <ColorForm<FormData> title='Hover Color' name={"properties.submitButtonStyle._hover.backgroundColor"} />
                        </Flex>
                    </FormAccordionItem>
                    <StyleDivider />
                    <FormAccordionItem title="Static Range Label Style">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.staticRangeLabelStyle.color'} />
                            <PalletForm<FormData> title="Range Colors" name="properties.rangeColors" max={2} />
                        </Flex>
                    </FormAccordionItem>
                </FormAccordion>
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={defaultTheme.components.DATE_RANGE} debug />
            </form>
        </FormProvider>
    </Box>
}

export default DateRangeStyle;