import { Button } from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ComponentUI } from '@types';
import { useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { selectToken } from 'src/redux/features/auth/authSlice';
import {
    selectActiveReportPage,
    selectAppliedFilters
} from 'src/redux/features/blueprint/bluePrintSlice';
import * as authService from 'src/auth/authService';

// TODO: use .env once pdf generator is on the production server
const API_URL = import.meta.env.VITE_API_URL;

const DownloadReportButton = ({ properties }: ComponentUI) => {
    const { state } = useCanvas();
    const params = useParams<{
        reportSlug: string;
        viewSlug: string;
        clientId: string;
    }>();
    const inEditor = state.useEditorReport;
    const isSharedReport = state.useSharedReportAPI;
    const reportToken = useAppSelector(selectToken);
    const [loading, setLoading] = useState(
        properties?.component?.debugLoading ? properties.component.debugLoading : false
    );

    const [error, setError] = useState<Error | null>(null);
    const appliedFilters = useAppSelector(selectAppliedFilters);
    const reportPage = useAppSelector(selectActiveReportPage);
    const layoutWidth = reportPage?.layout?.pageWidth ?? 0;
    const layoutHeight = reportPage?.layout?.pageHeight ?? 0;
    const errorToastDescription = properties?.component?.errorToastDescription
        ? properties.component.errorToastDescription
        : 'An error occurred while downloading the report.';

    const trigger = async () => {
        setLoading(true);
        let token = reportToken;

        if (!token) {
            const auth = authService.getTokenResponseFromStorage();
            token = auth?.access_token;
        }

        const endpoint = isSharedReport
            ? '/api/sharedReports.downloadPdf'
            : '/api/reports.downloadPdf';

        const downloadPdf = async () => {
            return await fetch(`${API_URL}${endpoint}`, {
                method: 'POST',
                body: JSON.stringify({
                    domain: window.location.origin,
                    clientId: params.clientId,
                    reportSlug: params.reportSlug,
                    viewSlug: params.viewSlug,
                    filters: btoa(encodeURI(JSON.stringify(appliedFilters))),
                    canvasSize: `${layoutWidth}:${layoutHeight}`,
                    isSharedReport: isSharedReport
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
        };

        const downloadWithRefreshToken = async () => {
            const response = await downloadPdf();

            if (response.status === 401 && !isSharedReport) {
                const auth = await authService.refreshToken();
                token = auth?.access_token;

                return await downloadPdf();
            }

            return response;
        };

        try {
            const response = await downloadWithRefreshToken();

            if (!response.ok) {
                toast(errorToastDescription, { type: 'error' });
                setError(new Error('Network response was not ok'));
                return;
            }

            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);

            // Create a link and click it to start the download
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Report';
            link.click();

            // Release the reference to the object URL
            window.URL.revokeObjectURL(url);
        } catch (error: unknown) {
            console.log(error);
            if (error instanceof Error) {
                setError(error);
                toast(errorToastDescription, { type: 'error' });
            } else {
                setError(new Error('An unknown error occurred.'));
                toast(errorToastDescription, { type: 'error' });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleClicked = () => {
        trigger();
    };

    const label = properties?.component?.label
        ? properties.component.label
        : 'Download Report';
    const loadingLabel = properties?.component?.loadingLabel
        ? properties.component.loadingLabel
        : 'Downloading...';
    const showDownloadIcon = properties?.component?.showIcon
        ? properties.component.showIcon
        : true;
    const downloadIconAlignment = properties?.component?.downloadIconAlign
        ? properties.component.downloadIconAlign
        : 'left';
    const leftDownloadIcon =
        downloadIconAlignment === 'left' ? (
            <FiDownload style={properties?.downloadIconStyle} />
        ) : null;
    const rightDownloadIcon =
        downloadIconAlignment === 'right' ? (
            <FiDownload style={properties?.downloadIconStyle} />
        ) : null;
    const style = loading
        ? { ...properties?.style, ...properties?.loadingStyle }
        : properties?.style;

    const extendedStyle = { ...style, display: 'flex' };

    return (
        <Button
            {...(inEditor ? { pointerEvents: 'none' } : {})}
            leftIcon={showDownloadIcon && leftDownloadIcon}
            rightIcon={showDownloadIcon && rightDownloadIcon}
            style={extendedStyle}
            isLoading={loading}
            loadingText={loadingLabel}
            disabled={loading}
            onClick={() => handleClicked()}
        >
            {label}
        </Button>
    );
};

export default DownloadReportButton;
