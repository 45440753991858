import { useListUsersQuery } from '@api';
import { Box, Card, CardBody, Flex, Icon, Link, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import SearchInput from 'src/components/SearchComponent';
import { BaseTable } from '@tasklogy/zircon-ui-components';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';

import LoadingStatus from '../../../../blueprint/components/LoadingStatus';
import { User } from 'src/redux/types/api';
import ActionCell from './ActionCell';
import { roleDisplayNames } from 'common/roles';
import AdminFormModal from 'src/pages/Settings/AdminSettings/AdminFormModal';

const columnHelper = createColumnHelper<User>();

const columns = [
    columnHelper.accessor('fullName', {
        cell: (info) => <strong>{info.getValue()}</strong>,
        header: () => 'Name'
    }),
    columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: () => 'E-mail'
    }),
    columnHelper.accessor('role', {
        cell: (info) => {
            const role = info.getValue();
            const displayName = roleDisplayNames[role];
            return displayName;
        },
        header: () => 'Role'
    }),
    columnHelper.accessor('id', {
        id: 'action',
        cell: (info) => <ActionCell user={info.row.original} />,
        header: () => 'Action'
    })
];

const AddAdminUserAction = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Link variant="defaultSmall" onClick={onOpen}>
                <Box display="flex" alignItems="center" gap="5px">
                    <Icon as={FiPlus}></Icon>
                    Create admin
                </Box>
            </Link>
            <AdminFormModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};

const AdminUsersTable = () => {
    const [search, setSearch] = useState<string>('');
    const { data, isLoading } = useListUsersQuery(undefined);

    return (
        <Box textAlign="center">
            <LoadingStatus isLoading={isLoading}>
                <Card>
                    <CardBody>
                        <Flex
                            justifyContent={'end'}
                            alignItems="center"
                            mb="1.5rem"
                            gap="20px"
                        >
                            <SearchInput search={search} setSearch={setSearch} />
                            <AddAdminUserAction />
                        </Flex>

                        {data && <BaseTable columns={columns} data={data} />}
                    </CardBody>
                </Card>
            </LoadingStatus>
        </Box>
    );
};
export default AdminUsersTable;
