import { Flex, Text } from '@chakra-ui/react';
import { PaginationState } from '@tanstack/react-table';
import { BlueprintTableProperties } from '@types';
import { SingleValue } from 'react-select';
import CustomSelect from '../CustomSelect';
import PaginationButtons from './PaginationButtons';
import { z } from 'zod';
import { styleValidator } from 'common/validators';

const PAGINATION_OPTIONS = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' }
];

interface Table {
    getState: () => {
        pagination: PaginationState;
    };
    setPageSize: (size: number) => void;
    getPageCount: () => number;
    getRowModel: () => {
        rows: any[];
    };
    nextPage: () => void;
    previousPage: () => void;
    setPageIndex: (index: number) => void;
    getRowCount: () => number;
}

type TabbleProperties = z.infer<typeof styleValidator.table>['properties']

interface PaginationProps {
    properties: TabbleProperties;
    table: Table;
}

export const Pagination = ({ properties, table }: PaginationProps) => {
    const { paginationRowsPerPageStyle, paginationButtonsStyle } = properties;

    const {
        pagination: { pageSize, pageIndex }
    } = table.getState();

    return (
        <Flex justifyContent="end" alignItems="center" gap="1rem">
            <Flex alignItems="center">
                <Text
                    fontWeight={600}
                    fontSize={12}
                    style={paginationRowsPerPageStyle?.labelStyle}
                >
                    {paginationRowsPerPageStyle?.label || 'Rows per page: '}
                </Text>
                <CustomSelect
                    options={PAGINATION_OPTIONS}
                    value={{ value: pageSize, label: pageSize.toString() }}
                    onChange={(newValue: SingleValue<{ value: any; label: any }>) => {
                        table.setPageSize(Number(newValue?.value));
                    }}
                    properties={{
                        controlStyle: {
                            border: 'none',
                            padding: '0',
                            width: '7rem',
                            background: 'transparent'
                        },
                        indicatorSeparatorStyle: {
                            display: 'none'
                        },
                        singleValueStyle: {
                            padding: 0,
                            fontSize: '12px',
                            color: 'white'
                        },
                        dropdownIndicatorStyle: {
                            padding: '0',
                            color: 'white'
                        },
                        valueContainerStyle: {
                            padding: 0
                        },
                        menuStyle: {
                            zIndex: 150
                        },
                        ...paginationRowsPerPageStyle
                    }}
                />
            </Flex>
            <PaginationButtons
                pageCount={table.getPageCount()}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRowsCount={table.getRowCount()}
                onNextPage={table.nextPage}
                onPreviousPage={table.previousPage}
                onSetPageIndex={table.setPageIndex}
                styles={paginationButtonsStyle}
            />
        </Flex>
    );
};
