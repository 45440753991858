export const cardStyle = {
    background: 'white',
    width: '100%',
    height: '100%',
    boxShadow: '0px 4px 10px 0px #000000',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    borderLeftColor: 'rgba(0, 0, 0, 0.1)',
    borderRightColor: 'rgba(0, 0, 0, 0.1)',
    borderTopColor: 'rgba(0, 0, 0, 0.1)',
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
};
export const pallet = [
    '#2b3674',
    'rgb(255, 0, 6)',
    'rgb(97, 97, 97)',
    'rgb(26, 115, 232)',
    '#2f1ae8',
    '#343a40',
    '#bbabd6',
    '#7CB342'
];
export const borderAll = {
    border: '1px solid',
    borderTopColor: 'white',
    borderRightColor: 'white',
    borderBottomColor: 'white',
    borderLeftColor: 'white'
};
export const cartesianGrid = {
    stroke: '#E7E7E7',
    opacity: 1,
    strokeDasharray: '3 3'
};
export const chartTooltip = {
    style: {
        padding: '0.5rem',
        borderRadius: 5,
        backgroundColor: 'white'
    },
    textStyle: {
        color: 'black',
        fontWeight: 'bolder',
        textTransform: 'uppercase'
    }
};
export const linearGradients = [
    {
        x1: '3',
        x2: '0',
        y1: '3',
        y2: '0',
        stops: [
            {
                offset: '0%',
                stopColor: 'rgb(166,36,117)',
                stopOpacity: 1
            },
            {
                offset: '50%',
                stopColor: 'rgba(199,66,149,1)',
                stopOpacity: 1
            },
            {
                offset: '100%',
                stopColor: 'rgba(233,133,195,1)',
                stopOpacity: 0
            }
        ],
        legendSymbol: 'square',
        legendSymbolFillColor: 'rgb(166,36,117)',
        legendSymbolTextColor: 'white'
    },
    {
        x1: '1',
        x2: '0',
        y1: '0',
        y2: '3',
        stops: [
            {
                offset: '0%',
                stopColor: 'rgb(252,187,91)',
                stopOpacity: 1
            },
            {
                offset: '50%',
                stopColor: 'rgba(52,122,131,1)',
                stopOpacity: 1
            },
            {
                offset: '100%',
                stopColor: 'rgba(142,201,208,1)',
                stopOpacity: 0
            }
        ],
        legendSymbolFillColor: '#39D7EC',
        legendSymbolTextColor: 'white'
    },
    {
        x1: '3',
        x2: '0',
        y1: '0',
        y2: '0',
        stops: [
            {
                offset: '0%',
                stopColor: 'rgba(252,187,91, 1)',
                stopOpacity: 1
            },
            {
                offset: '50%',
                stopColor: 'rgba(52,122,131,1)',
                stopOpacity: 1
            },
            {
                offset: '100%',
                stopColor: 'rgba(142,201,208,1)',
                stopOpacity: 0
            }
        ],
        legendSymbolFillColor: '#FCBB5B',
        legendSymbolTextColor: 'white'
    }
];
export const compareStyle = {
    upColor: '#05CD99',
    downColor: '#CD0508',
    fontSize: '12px',
    lineHeight: '20px',
    fontFamily: 'Arial',
    fontWeight: 500,
};
