import { z } from 'zod';
import validator from 'validator';
const create = z.object({
    email: z.string().min(1, 'Email is required.').email({ message: 'Invalid email format.' }),
    firstName: z.string().min(1, { message: 'First name is required.' }),
    lastName: z.string().min(1, { message: 'Last name is required.' }),
    phoneNumber: z
        .string()
        .refine((value) => (!value ? true : validator.isMobilePhone(value)), {
        message: 'Nesprávny formát telefónneho čísla'
    })
        .optional()
        .nullable(),
    role: z.string(),
    password: z.string().min(6, { message: 'Password must be at least 6 characters long.' })
});
const update = z.object({
    id: z.number(),
    firstName: z.string().min(1, { message: 'First name is required.' }),
    lastName: z.string().min(1, { message: 'Last name is required.' }),
    phoneNumber: z
        .string()
        .refine((value) => (!value ? true : validator.isMobilePhone(value)), {
        message: 'Nesprávny formát telefónneho čísla'
    })
        .optional()
        .nullable(),
    role: z.string(),
    password: z
        .string()
        .min(6, { message: 'Password must be at least 6 characters long.' })
        .optional()
});
const remove = z.object({
    id: z.number()
});
export default {
    create,
    update,
    remove
};
