import { CheckIcon, CopyIcon, DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text
} from '@chakra-ui/react';
import { componentTypeToName } from 'common/constants';
import { ComponentType } from 'common/enums';
import { Component } from 'common/types';
import { MdCancel } from 'react-icons/md';
import { PiResizeFill } from 'react-icons/pi';
import { SlOptionsVertical } from 'react-icons/sl';
import { useHasScopePermissions } from 'src/auth/useHasScopePermissions';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { getEnv } from 'src/utils';

interface CanvasComponentMenuProps {
    component: Component;
    showMenu: boolean;
    showActions?: boolean;
}

const env = getEnv();

const CanvasComponentMenu: React.FC<CanvasComponentMenuProps> = ({
    showMenu,
    component,
    showActions = false
}) => {
    const { state } = useCanvas();
    const hasEditorPermission =
        useHasScopePermissions({
            any: ['editor']
        }) || env === 'test';

    const { actions, eventEmitter } = useCanvas();
    if (!showMenu && !showActions) {
        return null;
    }

    return (
        <div
            id="canvas-component-menu"
            style={{
                backgroundColor: showMenu || showActions ? '#EFEFEF' : 'transparent',
                position: 'absolute',
                width: component.w,
                minWidth: '150px',
                top: component.y > 40 ? -42 : component.h + 2,
                height: 40,
                justifyContent: 'space-between',
                display: showMenu || showActions ? 'flex' : 'none',
                transform: `translate(${component.x}px, ${component.y}px)`,
                zIndex: 1000,
                alignItems: 'center',
                paddingLeft: '1rem'
            }}
        >
            <Text isTruncated>{componentTypeToName[component.type]}</Text>
            {showMenu && (
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<SlOptionsVertical />}
                        variant="icon"
                    />
                    <MenuList>
                        {[ComponentType.IMAGE, ComponentType.LOGO].includes(
                            component.type
                        ) && (
                            <MenuItem
                                icon={<PiResizeFill />}
                                onClick={() => actions.resetAspectRatio(component.id)}
                            >
                                Reset aspect ratio
                            </MenuItem>
                        )}
                        {ComponentType.DATE_RANGE !== component.type && (
                            <MenuItem
                                icon={<DeleteIcon />}
                                onClick={() => actions.deleteObject(component.id)}
                            >
                                Delete
                            </MenuItem>
                        )}
                        {hasEditorPermission && (
                            <>
                                {ComponentType.DATE_RANGE !== component.type && (
                                    <MenuItem
                                        icon={<ExternalLinkIcon />}
                                        onClick={() => actions.duplicateObject(component)}
                                    >
                                        Duplicate Object
                                    </MenuItem>
                                )}
                                <MenuItem
                                    icon={<CopyIcon />}
                                    onClick={() => actions.copyStyles(component)}
                                >
                                    Copy Styles
                                </MenuItem>
                                {state.canPasteStyles && (
                                    <MenuItem
                                        icon={<CopyIcon />}
                                        onClick={() => actions.pasteStyles(component)}
                                    >
                                        Paste Styles
                                    </MenuItem>
                                )}
                            </>
                        )}
                    </MenuList>
                </Menu>
            )}
            {showActions && (
                <Flex>
                    <IconButton
                        aria-label="Cancel"
                        icon={<MdCancel />}
                        variant="icon"
                        onClick={() => eventEmitter.emit('cancel-inline-edit')}
                    />
                    <IconButton
                        aria-label="Save"
                        icon={<CheckIcon />}
                        variant="icon"
                        onClick={() => eventEmitter.emit('save-inline-edit')}
                    />
                </Flex>
            )}
        </div>
    );
};

export default CanvasComponentMenu;
