import { cardStyle, compareStyle } from './common.js';
const defaultSingleValue = {
    properties: {
        label: {
            color: 'rgb(112, 126, 174)',
            fontSize: '16px',
            lineHeight: '20px',
            fontFamily: 'Arial',
            fontWeight: 500,
        },
        style: {},
        value: {
            color: 'rgb(43, 54, 116)',
            fontSize: '20px',
            marginTop: '0.25rem',
            fontWeight: '400',
            lineHeight: '36px',
            whiteSpace: 'nowrap',
            fontFamily: 'Arial',
        },
        cardStyle: cardStyle,
        compareStyle: compareStyle
    }
};
export const copySingleValueStyles = (properties) => {
    const _properties = { ...properties };
    return _properties;
};
export default defaultSingleValue;
