import { ValueFormatUnitType } from '@types';
import _ from 'lodash';
import { SHARED_REPORT_PATH } from 'src/constants';
import { ColumnFormatType } from 'common/enums';
import { DateTime } from 'luxon';

interface FormatParams {
    locale?: string;
    decimalPlaces?: number;
    compactDisplay?: 'short' | 'long';
    currency?: ValueFormatUnitType[ColumnFormatType.CURRENCY];
    dateFormat?: string;
}

export function formatPrice(value: number | bigint, params: FormatParams = {}) {
    const { locale, currency, decimalPlaces, compactDisplay } = params;

    const formated = new Intl.NumberFormat(locale ?? 'sk-SK', {
        style: 'currency',
        currency: currency ? currency.toUpperCase() : 'EUR',
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
        useGrouping: true,
        notation: compactDisplay ? 'compact' : undefined,
        compactDisplay: compactDisplay
    }).format(value);

    return value === null ? '-' : formated;
}

export function formatNumber(value: number | bigint, params: FormatParams = {}) {
    const { locale, decimalPlaces, compactDisplay } = params;

    const formated = new Intl.NumberFormat(locale ?? 'sk-SK', {
        minimumFractionDigits: decimalPlaces ?? 0,
        maximumFractionDigits: decimalPlaces ?? 1,
        useGrouping: true,
        notation: compactDisplay ? 'compact' : undefined,
        compactDisplay: compactDisplay
    }).format(value);

    return value === null ? '-' : formated;
}

export function formatPercentage(value: number, params: FormatParams = {}) {
    const { locale, decimalPlaces } = params;

    const formated = new Intl.NumberFormat(locale ?? 'sk-SK', {
        style: 'percent',
        minimumFractionDigits: decimalPlaces ?? 0,
        maximumFractionDigits: decimalPlaces ?? 2,
        useGrouping: true
    }).format(value / 100);

    return value === null ? '-' : formated;
}

export const campaignTypesMap: Record<string, string> = {
    video: 'Video',
    display: 'Zobrazenie',
    search: 'Vyhľadávanie'
};

export function isEgual(obj1: never, obj2: never): boolean {
    // If both are strictly equal, return true
    if (obj1 === obj2) return true;

    // If either is not an object or is null, return false
    if (
        typeof obj1 !== 'object' ||
        obj1 === null ||
        typeof obj2 !== 'object' ||
        obj2 === null
    )
        return false;

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If they don't have the same number of keys, return false
    if (keys1.length !== keys2.length) return false;

    // Check if all keys in obj1 exist in obj2 and their values are deeply equal
    for (const key of keys1) {
        if (!keys2.includes(key)) return false;
        if (!isEgual(obj1[key], obj2[key])) return false;
    }

    // If none of the previous checks failed, the objects are deeply equal
    return true;
}
export const markdownToHtml = (markdown: string) => {
    // Replace headers (e.g., # Header -> <h1>Header</h1>)
    markdown = markdown.replace(/^(#+)\s+(.*)$/gm, (_match, hashes, content) => {
        const level = hashes.length;
        return `<h${level}>${content}</h${level}>`;
    });

    // Replace bold text (e.g., **bold** -> <strong>bold</strong>)
    markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Replace italic text (e.g., *italic* -> <em>italic</em>)
    markdown = markdown.replace(/\*(.*?)\*/g, '<em>$1</em>');

    // Replace links (e.g., [link](url) -> <a href="url">link</a>)
    markdown = markdown.replace(
        /\[(.*?)\]\((.*?)\)/g,
        '<a href="$2" target="_blank" style="text-decoration: underline">$1</a>'
    );

    // Replace unordered lists (e.g., - item -> <ul><li>item</li></ul>)
    markdown = markdown.replace(/^\s*-\s+(.*)$/gm, '<ul><li>$1</li></ul>');

    // Replace ordered lists (e.g., 1. item -> <ol><li>item</li></ol>)
    markdown = markdown.replace(/^\s*\d+\.\s+(.*)$/gm, '<ol><li>$1</li></ol>');

    // Replace line breaks (e.g., two spaces at the end of a line -> <br>)
    markdown = markdown.replace(/ {2,}$/gm, '<br>');

    return markdown;
};

export const matchPathname = (pattern: string, path: string) => {
    let params = [];
    let regexpSource =
        '^' +
        pattern
            .replace(/\/*\*?$/, '') // Ignore trailing / and /*, we'll handle it below
            .replace(/^\/*/, '/') // Make sure it has a leading /
            .replace(/[\\.*+^${}|()[\]]/g, '\\$&') // Escape special regex chars
            .replace(/\/:([\w-]+)(\?)?/g, (_: string, paramName: string, isOptional) => {
                params.push({ paramName, isOptional: isOptional != null });
                return isOptional ? '/?([^\\/]+)?' : '/([^\\/]+)';
            });
    regexpSource += '\\/*$';
    const matcher = new RegExp(regexpSource);
    const match = path.match(matcher);
    return match;
};

export const formatGenericValue = (
    value: any,
    type: ColumnFormatType,
    formatParams?: FormatParams
) => {
    if (value === null) {
        return '-';
    }

    switch (type) {
        case ColumnFormatType.CURRENCY:
            return formatPrice(value, formatParams);
        case ColumnFormatType.NUMBER:
            return formatNumber(value, formatParams);
        case ColumnFormatType.PERCENTAGE:
            return formatPercentage(value, formatParams);
        case ColumnFormatType.DATE:
        case ColumnFormatType.WEEK:
            return DateTime.fromISO(value).toFormat(
                formatParams?.dateFormat ?? 'dd.MM.yyyy'
            );
        case ColumnFormatType.MONTH:
            return DateTime.fromISO(value).toFormat(formatParams?.dateFormat ?? 'LLLL');
        default:
            return value;
    }
};

export const isPuppeteer = navigator.userAgent.includes('Puppeteer');

export const navigateTo = (
    view: { slug: string },
    params: { reportSlug?: string; viewSlug?: string }
) => {
    let to = `${location.pathname}`;
    if (location.pathname.includes('reporty')) {
        if (params.viewSlug) {
            return to.replace(params.viewSlug, view.slug);
        } else {
            return to + view.slug;
        }
    } else {
        return `/${SHARED_REPORT_PATH}/${params.reportSlug}/${view.slug}`;
    }
};

export const getBaseUrl = () => {
    return import.meta.env.VITE_API_URL as string;
};

export const isImage = (url: URL): boolean => {
    if (!url.pathname) return false;
    const imageExtensions = ['jpeg', 'jpg', 'gif', 'png', 'svg'];
    const urlExtension = url.pathname.split('.').pop()?.toLowerCase();
    return imageExtensions.includes(urlExtension || '');
};

export const isIframe = (url: URL): boolean => {
    if (!url.pathname) return false;
    return url.pathname.includes('.html');
};

export const slugToTitle = (slug?: string) => {
    if (!slug) return '';
    const title = slug.split('-').map((word) => word[0].toUpperCase() + word.slice(1));
    return title.join(' ');
};

export const setEmptyOrStr = (v: unknown) => {
    if (_.isString(v) && _.isEmpty(v)) {
        return null;
    } else {
        return v;
    }
};

type Env = 'test' | 'local' | 'development' | 'production' | 'pre-production' | 'mds';

export const getEnv = () => {
    return import.meta.env.VITE_ENV as Env;
};

const productionEnvs = ['production', 'pre-production', 'mds'];

export const isProduction = () => {
    const env = getEnv();
    if (!env) return false;

    return productionEnvs.includes(env);
};
