import { Button, Flex } from '@chakra-ui/react';
import { ClientIdParams, ReportSlugParams, ViewSlugParams } from '@router';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/redux';
import {
    selectActiveReport,
    selectActiveReportPageId
} from 'src/redux/features/blueprint/bluePrintSlice';
import { PathNames } from 'src/router/router';

const ReportViewsNavigation = () => {
    const params = useParams<ReportSlugParams & ViewSlugParams & ClientIdParams>();
    const activeReportPageId = useAppSelector(selectActiveReportPageId);
    const activeReport = useAppSelector(selectActiveReport);
    const navigate = useNavigate();

    const handleNavigate = (pageSlug: string) => {
        if (params.clientId && params.reportSlug) {
            const path = generatePath(PathNames.DASHBOARD_REPORT, {
                clientId: params.clientId,
                reportSlug: params.reportSlug,
                viewSlug: pageSlug
            });

            navigate(path);
        }
    };
    return (
        <Flex mb="1rem" gap="1rem">
            {activeReport?.pages?.map((page, index: number) => {
                return (
                    <Button
                        key={index}
                        variant={activeReportPageId === page.id ? 'solid' : 'outline'}
                        size="sm"
                        onClick={() => handleNavigate(page.slug)}
                    >
                        {page.displayName}
                    </Button>
                );
            })}
        </Flex>
    );
};

export default ReportViewsNavigation;
