import { Button, Flex, Text } from '@chakra-ui/react';
import { ActionDelete, ActionEdit, HappyModal } from '@tasklogy/zircon-ui-components';
import { useModal } from 'src/hooks/useModal';
import { User } from 'src/redux/types/api';
import { useDeleteUserMutation } from '@api';
import { toast } from 'react-toastify';
import { useAuth } from 'src/auth/useAuth';
import AdminFormModal from '../AdminFormModal';
import { useHasScopePermissions } from 'src/auth/useHasScopePermissions';

interface Props {
    user: User;
}
export default function ActionCell({ user }: Props) {
    const { isOpen, open, close } = useModal();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal
    } = useModal();
    const { user: currentUser } = useAuth();
    const [deleteUser] = useDeleteUserMutation();
    const hasManageSuperAdminPermission = useHasScopePermissions({
        global: ['manage-super-admins']
    });

    const hasManageReportingoAdminPermission = useHasScopePermissions({
        global: ['manage-reportingo-admins']
    });

    //const canDeleteAndEdit = currentUser?.id !== user.id && user?.role !== 'super-admin';

    const canDeleteAndEdit = () => {
        if (currentUser?.id === user.id) {
            return false;
        }

        if (user?.role === 'super-admin') {
            return hasManageSuperAdminPermission;
        }

        if (user?.role === 'reportingo-admin') {
            return hasManageReportingoAdminPermission;
        }

        return false;
    };

    console.log('canDeleteAndEdit', canDeleteAndEdit());

    const handleDelete = async () => {
        try {
            await deleteUser({ id: user.id }).unwrap();
            toast.success('User deleted successfully.');
        } catch (e) {
            toast.error('An error occurred while deleting user.');
            console.error(e);
        } finally {
            closeDeleteModal();
        }
    };

    return (
        <Flex
            alignItems="center"
            justifyContent="left"
            visibility={canDeleteAndEdit() ? 'visible' : 'hidden'}
        >
            <ActionEdit onClick={open} />
            <ActionDelete onClick={openDeleteModal} />
            <AdminFormModal isOpen={isOpen} onClose={close} existingUser={user} />
            <HappyModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                <HappyModal.Title>Attention</HappyModal.Title>
                <HappyModal.Body>
                    Are you sure you want to delete user
                    <Text as="span" fontWeight="bold">
                        {user?.fullName}
                    </Text>
                    ?
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={closeDeleteModal}>
                        No
                    </Button>
                    <Button variant="solid" onClick={handleDelete}>
                        Yes
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
        </Flex>
    );
}
