import {
    addDays,
    addMonths,
    endOfDay,
    endOfMonth,
    endOfWeek,
    startOfDay,
    startOfMonth,
    startOfWeek,
    addYears,
    startOfYear,
    endOfYear,
    setDefaultOptions
} from 'date-fns';
import { createStaticRanges } from 'react-date-range';

setDefaultOptions({
    weekStartsOn: 1,
});

export const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfLastYear: startOfYear(addYears(new Date(), -1)),
    endOfLastYear: endOfYear(addYears(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date())
};

export const staticRanges = createStaticRanges([
    {
        label: 'Yeasterday',
        range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: defineds.endOfYesterday
        }),
        hasCustomRendering: true
    },

    {
        label: 'This week',
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfYesterday
        }),
        hasCustomRendering: true
    },
    {
        label: 'Last week',
        range: () => ({
            startDate: defineds.startOfLastWeek,
            endDate: defineds.endOfLastWeek
        }),
        hasCustomRendering: true
    },
    {
        label: 'This month',
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfYesterday
        }),
        hasCustomRendering: true
    },
    {
        label: 'Last month',
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth
        }),
        hasCustomRendering: true
    },
    {
        label: 'This year',
        range: () => ({
            startDate: defineds.startOfYear,
            endDate: defineds.endOfYesterday
        }),
        hasCustomRendering: true
    },
    {
        label: 'Last year',
        range: () => ({
            startDate: defineds.startOfLastYear,
            endDate: defineds.endOfLastYear
        }),
        hasCustomRendering: true
    },

    {
        label: 'Last 30 days',
        range: () => ({
            startDate: addDays(new Date(), -30),
            endDate: defineds.endOfYesterday
        }),
        hasCustomRendering: true
    },
    {
        label: 'Last 7 days',
        range: () => ({
            startDate: addDays(new Date(), -7),
            endDate: defineds.endOfYesterday
        }),
        hasCustomRendering: true
    }
]);
