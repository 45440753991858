import { Box, Flex, Link } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/auth/useAuth';
import privateRoutes from 'src/router/private';
import { PathNames } from 'src/router/router';
import { Route } from '../../router/types';

interface ILayoutSettingsLink {
    route: Route;
}

const LayoutSettingsLink: React.FC<ILayoutSettingsLink> = ({ route }) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <Link
            variant="textLink"
            onClick={() => navigate(route.path)}
            style={
                route.path === location.pathname ||
                (location.pathname === PathNames.USER_SETTINGS &&
                    route.path === PathNames.USER_SETTINGS_PERSONAL_DATA)
                    ? { color: '#26E1EF', fontWeight: 600 }
                    : {}
            }
        >
            {route?.handle?.name}
        </Link>
    );
};

export const LayoutSettings = () => {
    const routes = privateRoutes();
    const { hasScopePermissions, hasRoles } = useAuth();
    const settingsRoute = routes?.[0]?.children?.find(
        (route: Route) => route.path === PathNames.USER_SETTINGS
    );

    const userSettingsRoutes =
        settingsRoute?.children
            ?.filter(
                (route: Route) =>
                    route.path === PathNames.USER_SETTINGS_PERSONAL_DATA ||
                    route.path === PathNames.USER_SETTINGS_PASSWORD
            )
            .filter((route: Route) => {
                const authPermissions: (boolean | undefined)[] = [];
                if (route.handle?.auth?.withScopePermissions) {
                    authPermissions.push(
                        hasScopePermissions(route.handle.auth.withScopePermissions)
                    );
                }
                if (route.handle?.auth?.hasRoles) {
                    authPermissions.push(hasRoles(route.handle.auth.hasRoles));
                }

                if (authPermissions.length === 0) {
                    return false;
                }
                return authPermissions.some(Boolean);
            }) ?? [];

    const projectSettingsRoutes =
        settingsRoute?.children
            ?.filter(
                (route: Route) =>
                    route.path === PathNames.USER_SETTINGS_COMPANY_DATA ||
                    route.path === PathNames.USER_SETTINGS_TEAM_MEMBERS
            )
            .filter((route: Route) => {
                const authPermissions: (boolean | undefined)[] = [];
                if (route.handle?.auth?.withScopePermissions) {
                    authPermissions.push(
                        hasScopePermissions(route.handle.auth.withScopePermissions)
                    );
                }
                if (route.handle?.auth?.hasRoles) {
                    authPermissions.push(hasRoles(route.handle.auth.hasRoles));
                }

                if (authPermissions.length === 0) {
                    return false;
                }
                return authPermissions.some(Boolean);
            }) ?? [];

    const adminSettingsRoutes =
        settingsRoute?.children
            ?.filter((route: Route) => route.path === PathNames.USER_SETTINGS_ADMIN_USERS)
            .filter((route: Route) => {
                const authPermissions: (boolean | undefined)[] = [];
                if (route.handle?.auth?.withScopePermissions) {
                    authPermissions.push(
                        hasScopePermissions(route.handle.auth.withScopePermissions)
                    );
                }
                if (route.handle?.auth?.hasRoles) {
                    authPermissions.push(hasRoles(route.handle.auth.hasRoles));
                }

                if (authPermissions.length === 0) {
                    return false;
                }
                return authPermissions.some(Boolean);
            }) ?? [];

    return (
        <Flex h="100%">
            <Box
                w="100%"
                maxW="300px"
                px="35px"
                pb="60px"
                pt="40px"
                bg="white"
                mb="0.375rem"
            >
                {userSettingsRoutes?.length > 0 && (
                    <Box
                        as="h3"
                        mb="20px"
                        fontSize="21px"
                        fontWeight="bold"
                        color="#525A66"
                    >
                        User settings
                    </Box>
                )}
                {userSettingsRoutes?.map((route: Route) => (
                    <Box key={route.path}>
                        <LayoutSettingsLink route={route} />
                    </Box>
                ))}
                {adminSettingsRoutes?.length > 0 && (
                    <Box
                        as="h3"
                        my="20px"
                        fontSize="21px"
                        fontWeight="bold"
                        color="#525A66"
                    >
                        Admin settings
                    </Box>
                )}
                {adminSettingsRoutes?.map((route: Route) => (
                    <Box key={route.path} mb="0.375rem">
                        <LayoutSettingsLink route={route} />
                    </Box>
                ))}
                {projectSettingsRoutes.length > 0 && (
                    <Box
                        as="h3"
                        my="20px"
                        fontSize="21px"
                        fontWeight="bold"
                        color="#525A66"
                    >
                        Team settings
                    </Box>
                )}
                {projectSettingsRoutes?.map((route: Route) => (
                    <Box key={route.path} mb="0.375rem">
                        <LayoutSettingsLink route={route} />
                    </Box>
                ))}
            </Box>
            <Flex w="100%" bg="#F8F8F8">
                <Outlet />
            </Flex>
        </Flex>
    );
};
