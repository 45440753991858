import { Component } from 'common/types';
import { selectActiveReportPage } from 'src/redux/features/blueprint/bluePrintSlice';
import { useAppSelector } from './redux';

import { generateRandomId } from 'common/utils';
import { reportPageValidator } from 'common/validators';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { z } from 'zod';
import { useSetComponentConfig } from './useSetComponentConfig';
import { useCallback } from 'react';

export const useDuplicateComponent = () => {
    const setComponentConfig = useSetComponentConfig();
    const activeReportPage = useAppSelector(selectActiveReportPage);

    return useCallback(
        async (component: Component) => {
            assertIsDefined(activeReportPage);

            // compute new position with activeReportPage height and width
            const pageHeight = activeReportPage.layout.pageHeight;
            const pageWidth = activeReportPage.layout.pageWidth;
            const newY =
                component.y + 2 * component.h >= pageHeight
                    ? pageHeight - 2 * component.h
                    : component.y + component.h;
            const newX =
                component.x + 2 * component.w >= pageWidth
                    ? pageWidth - 2 * component.w
                    : component.x + component.w;

            component = {
                ...component,
                y: newY,
                x: newX,
                id: generateRandomId()
            };

            try {
                const validated = reportPageValidator.component.parse(component);
                await setComponentConfig(validated);
            } catch (err) {
                if (err instanceof z.ZodError) {
                    console.log(err.issues);
                }
            }
        },
        [activeReportPage, setComponentConfig]
    );
};
