import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ComponentType } from 'common/enums';
import { useEffect, useState } from 'react';
import ReportScreenshot from 'src/blueprint/pages/editor/components/TabPages/ReportScreenshot';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import {
    selectActiveReport,
    selectComponentById
} from 'src/redux/features/blueprint/bluePrintSlice';
import Setup from './Setup';
import Style from './Style';

const staticComponents = [
    ComponentType.TEXT,
    ComponentType.RECTANGLE,
    ComponentType.DOWNLOAD_BUTTON,
    ComponentType.NAVBAR
];

const PropertySidebarTabs = () => {
    const activeReport = useAppSelector(selectActiveReport);
    const {
        state: { activeSelectedComponentId }
    } = useCanvas();
    const componentToEdit = useAppSelector(
        selectComponentById(activeSelectedComponentId)
    );
    const showSetup = componentToEdit?.type
        ? !staticComponents.includes(componentToEdit.type)
        : false;
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const allTabs = [
        {
            name: 'Setup',
            component: <Setup />,
            show: showSetup
        },
        {
            name: 'Style',
            component: <Style />,
            show: true
        },
        {
            name: 'Report Screenshot',
            component: <ReportScreenshot />,
            show: activeReport?.isTemplate && !componentToEdit
        }
    ];

    const visibleTabs = allTabs.filter((tab) => tab.show);

    // Ensure the active index resets if the visible tab is "Style"
    useEffect(() => {
        if (visibleTabs.length === 1 && visibleTabs[0].name === 'Style') {
            setActiveTabIndex(0);
        }
    }, [visibleTabs]);

    useEffect(() => {
        if (
            !componentToEdit &&
            visibleTabs.find((tab) => tab.name === 'Report Screenshot')
        ) {
            setActiveTabIndex(0);
        }
    }, [activeReport, componentToEdit]);

    return (
        <Tabs
            variant="primary"
            index={activeTabIndex}
            onChange={(index) => setActiveTabIndex(index)}
        >
            <TabList>
                {visibleTabs.map((tab, index) => (
                    <Tab key={index}>{tab.name}</Tab>
                ))}
            </TabList>
            <TabPanels>
                {visibleTabs.map((tab, index) => (
                    <TabPanel key={index} pb="2rem">
                        {tab.component}
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
};

export default PropertySidebarTabs;
