import { z } from 'zod';
const create = z.object({
    name: z.string().min(3).max(255).optional(),
    teamId: z.number()
});
const update = z.object({
    id: z.number(),
    name: z
        .string({ message: 'Name is required.' })
        .min(2, { message: 'Name must be at least 2 characters long.' })
});
const remove = z.object({
    id: z.number()
});
const get = z.object({
    id: z.number()
});
const deleteConnectedSource = z.object({
    connectedSourceId: z.number()
});
const list = z.object({
    teamId: z.number().nullable()
});
const replaceConnectedSource = z.object({
    connectedSourceId: z.number(),
    newAdvertiserId: z.number()
});
export default {
    create,
    update,
    remove,
    get,
    deleteConnectedSource,
    replaceConnectedSource,
    list
};
