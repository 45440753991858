import { useCreateReportPageMutation, useUpdateReportMutation } from '@api';
import {
    Box,
    Flex,
    Icon,
    IconButton,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Text,
    Tooltip
} from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ReportPage } from 'common/types';
import { Reorder, useAnimationControls, useMotionValue } from 'framer-motion';
import { debounce } from 'lodash';
import {
    CSSProperties,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { IoAddCircle } from 'react-icons/io5';
import { RiFile4Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useHasScopePermissions } from 'src/auth/useHasScopePermissions';
import { useRaisedShadow } from 'src/editor/hooks/useRaisedShadow';
import { selectActiveReport } from 'src/redux/features/blueprint/bluePrintSlice';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { Page } from './Page';
import { TPage } from './types';
import { getEnv } from 'src/utils';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';

const liStyle: CSSProperties = {
    listStyle: 'none',
    padding: 0,
    margin: 0
};

const ulStyle: CSSProperties = {
    ...liStyle,
    position: 'relative',
    width: '360px',
    cursor: 'grab'
};

const ReorderItem = ({
    item,
    constraintsRef
}: {
    item: TPage;
    constraintsRef: RefObject<Element>;
}) => {
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);

    return (
        <Reorder.Item
            value={item}
            style={{ ...liStyle, boxShadow, y }}
            dragConstraints={constraintsRef}
        >
            <Page key={item.id} {...item} />
        </Reorder.Item>
    );
};

interface ReorderGroupProps {
    pages: ReportPage[];
    onReorderEnd: (items: ReportPage[]) => void;
    constraintsRef: RefObject<Element>;
}

const env = getEnv();

const ReorderGroupPages: React.FC<ReorderGroupProps> = ({
    pages,
    onReorderEnd,
    constraintsRef
}) => {
    const controls = useAnimationControls();
    const [items, setItems] = useState<ReportPage[]>(pages);

    useEffect(() => {
        setItems(pages);
    }, [pages]);

    const debouncedChangeHandler = useMemo(
        () =>
            debounce((value) => {
                onReorderEnd(value);
            }, 500),
        []
    );

    useEffect(() => {
        // fire if order has changed
        if (
            pages?.map((page) => page.id).join(',') !==
            items?.map((page) => page.id).join(',')
        ) {
            debouncedChangeHandler(items);
        }
        return () => debouncedChangeHandler.cancel();
    }, [items, debouncedChangeHandler]);

    return (
        <Reorder.Group
            axis="y"
            values={items}
            onReorder={setItems}
            style={ulStyle}
            animate={controls}
        >
            {items.map((item) => (
                <ReorderItem key={item.id} item={item} constraintsRef={constraintsRef} />
            ))}
        </Reorder.Group>
    );
};

interface PagesPopoverProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

const PagesPopover: React.FC<PagesPopoverProps> = ({ isOpen, setIsOpen }) => {
    const constraintsRef = useRef(null);
    const [createPage] = useCreateReportPageMutation();
    const activeReport = useAppSelector(selectActiveReport);
    const [updateReport] = useUpdateReportMutation();
    const { editorRef } = useCanvas();

    assertIsDefined(activeReport);

    // Close popover when clicking inside the editor
    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.addEventListener('click', () => setIsOpen(false));
        }
        return () => {
            if (editorRef.current) {
                editorRef.current.removeEventListener('click', () => setIsOpen(false));
            }
        };
    }, [editorRef.current]);

    const handleCreateReportPage = useCallback(() => {
        createPage({
            displayName: 'Untitled',
            slug: `untitled-${activeReport.pages.length + 1}`,
            reportId: activeReport?.id
        });
    }, [createPage, activeReport?.id]);

    const handleReorderEnd = useCallback(
        async (items: ReportPage[]) => {
            try {
                await updateReport({
                    id: activeReport.id,
                    pageOrder: items.map((page) => page.id)
                }).unwrap();
                toast.success('Pages reordered');
            } catch (error) {
                console.error(error);
                toast.error('Failed to reorder pages');
            }
        },
        [updateReport, activeReport.id]
    );

    const pages = useMemo(() => {
        if (Array.isArray(activeReport?.pageOrder)) {
            const pagesFormPageOrder =
                activeReport.pageOrder
                    .map((pageId) =>
                        activeReport.pages?.find((page) => page.id === pageId)
                    )
                    .filter((page): page is ReportPage => page !== undefined) ?? [];
            const leftOverPages =
                activeReport.pages?.filter(
                    (page) => activeReport.pageOrder?.indexOf(page.id) === -1
                ) ?? [];
            return [...pagesFormPageOrder, ...leftOverPages];
        } else {
            return activeReport?.pages ?? [];
        }
    }, [activeReport?.pageOrder, activeReport?.pages]);

    const hasEditorPermission =
        useHasScopePermissions({
            any: ['editor']
        }) || env === 'test';

    return (
        <Popover placement="right-start" closeOnBlur isOpen={isOpen}>
            <PopoverTrigger>
                <Box>
                    <Tooltip label="Pages" aria-label="Pages" placement="right">
                        <IconButton
                            variant="icon"
                            aria-label="Add new component"
                            onClick={() => setIsOpen(!isOpen)}
                            icon={<Icon h="60px" as={RiFile4Line} />}
                        />
                    </Tooltip>
                </Box>
            </PopoverTrigger>

            <Portal>
                <Box zIndex={99999} position="relative">
                    <PopoverContent className="editor-sidebar-content" width="100%">
                        <PopoverBody
                            display="flex"
                            flexDir="column"
                            gap="0.5rem"
                            ref={constraintsRef}
                        >
                            {isOpen && (
                                <ReorderGroupPages
                                    pages={pages}
                                    onReorderEnd={handleReorderEnd}
                                    constraintsRef={constraintsRef}
                                />
                            )}
                            {hasEditorPermission && (
                                <Flex
                                    w="fit-content"
                                    gap="10px"
                                    alignItems="center"
                                    onClick={() => handleCreateReportPage()}
                                    _hover={{
                                        cursor: 'pointer',
                                        borderBottom: '1px solid rgba(28, 115, 232, 1)'
                                    }}
                                >
                                    <Icon
                                        color="rgba(28, 115, 232, 1)"
                                        as={IoAddCircle}
                                    />
                                    <Text color="rgba(28, 115, 232, 1)">
                                        Add new page
                                    </Text>
                                </Flex>
                            )}
                        </PopoverBody>
                    </PopoverContent>
                </Box>
            </Portal>
        </Popover>
    );
};

export default PagesPopover;
