import { Box, Text } from '@chakra-ui/react';
import { DateRangeFilter } from 'common/types';
import { endOfYesterday } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useGetReportPageFiltersMutation } from 'src/redux/api';
import {
    selectActiveReportPage,
    selectAppliedFilters,
    setAppliedFilter
} from 'src/redux/features/blueprint/bluePrintSlice';
import DatePicker2, {
    IDateRanges
} from 'src/templates/blueprint/organisms/calendar/DatePicker2';
import { assertIsDefined, getFilterComponents } from '../utils';
import { useFetchReportPageData } from 'src/hooks/useFetchReportPageData';
import { z } from 'zod';
import { styleValidator } from 'common/validators';
import { format } from 'date-fns';

interface Props extends z.infer<typeof styleValidator.dateRange> {
    id: string;
}

const DatePickerFilter = (props: Props) => {
    const { id, properties, ...rest } = props;
    const { state } = useCanvas();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const appliedFilters = useAppSelector(selectAppliedFilters);
    const appliedFilter = appliedFilters.find((f) => f.id === id);
    const fetchPageData = useFetchReportPageData();
    const [fetchPageFilterOptions] = useGetReportPageFiltersMutation();

    assertIsDefined(activeReportPage);

    const filterConfiguration = rest as DateRangeFilter;

    const filterComponents = getFilterComponents(activeReportPage.components);

    const handleChanges = (dateRange: IDateRanges) => {
        const startDateString = format(dateRange.default.startDate, 'yyyy-MM-dd');
        const endDateString = format(dateRange.default.endDate, 'yyyy-MM-dd');
        let compareDateStrings = {
            compareStart: '',
            compareEnd: ''
        };

        if (dateRange?.compare) {
            compareDateStrings = {
                compareStart: format(dateRange.compare.startDate, 'yyyy-MM-dd'),
                compareEnd: format(dateRange.compare.endDate, 'yyyy-MM-dd')
            };
        }

        const newFilters = appliedFilters.map((f) => {
            if (f.id === id) {
                return {
                    id,
                    value: {
                        start: startDateString,
                        end: endDateString,
                        ...compareDateStrings
                    }
                };
            }

            return {
                id: f.id,
                value: f.value
            };
        });
        fetchPageData({
            filterValues: newFilters,
            isSharedReport: state.useSharedReportAPI
        });
        fetchPageFilterOptions({
            reportPageId: activeReportPage.id,
            filterIds: filterComponents?.map((f) => f.id) ?? [],
            dateRange: {
                start: startDateString,
                end: endDateString,
                ...(compareDateStrings.compareStart && compareDateStrings.compareEnd
                    ? {
                          compareStart: compareDateStrings.compareStart,
                          compareEnd: compareDateStrings.compareEnd
                      }
                    : {})
            },
            isSharedReport: state.useSharedReportAPI
        });

        setSearchParams({
            ...searchParams,
            [id]:
                `${startDateString}_${endDateString}` +
                (dateRange.compare?.startDate
                    ? `__${compareDateStrings.compareStart}_${compareDateStrings.compareEnd}`
                    : '')
        });

        dispatch(
            setAppliedFilter({
                id,
                value: {
                    start: dateRange.default.startDate.getTime(),
                    end: dateRange.default.endDate.getTime(),
                    ...(dateRange?.compare
                        ? {
                              compareStart: dateRange?.compare?.startDate.getTime(),
                              compareEnd: dateRange?.compare?.endDate.getTime()
                          }
                        : {})
                }
            })
        );
    };

    const minDate = filterConfiguration?.minDate
        ? new Date(filterConfiguration?.minDate)
        : new Date('2022-01-01');
    const maxDate = filterConfiguration?.maxDate
        ? new Date(filterConfiguration?.maxDate)
        : endOfYesterday();

    return (
        <Box id={id} width="100%" height="100%">
            {properties?.label && (
                <Text
                    color="white"
                    fontSize="medium"
                    fontWeight={400}
                    marginBottom={2}
                    style={properties?.labelStyle}
                >
                    {properties?.label}
                </Text>
            )}
            <DatePicker2
                properties={properties}
                value={{
                    default: {
                        startDate: new Date(appliedFilter?.value?.start ?? 0),
                        endDate: new Date(appliedFilter?.value?.end ?? endOfYesterday()),
                        key: 'default'
                    },
                    ...(appliedFilter?.value?.compareStart &&
                    appliedFilter?.value?.compareEnd
                        ? {
                              compare: {
                                  startDate: new Date(
                                      appliedFilter?.value?.compareStart ?? 0
                                  ),
                                  endDate: new Date(
                                      appliedFilter?.value?.compareEnd ?? endOfYesterday()
                                  ),
                                  key: 'compare'
                              }
                          }
                        : {})
                }}
                minDate={minDate.getTime()}
                maxDate={maxDate.getTime()}
                onChange={(dateRange) => handleChanges(dateRange)}
            />
        </Box>
    );
};

export default DatePickerFilter;
