import { AvatarGroup, Flex, Image, Tooltip } from '@chakra-ui/react';

import adform from '@assets/svg/dataSources/adform.png';
import dv360 from '@assets/svg/dataSources/dv360.svg';
import facebookInsights from '@assets/svg/dataSources/facebookInsights.svg';
import gemiusGDE from '@assets/svg/dataSources/gemiusGde.png';
import googleAds from '@assets/svg/dataSources/googleAds.svg';
import googleAnalytics from '@assets/svg/dataSources/googleAnalytics.svg';
import instagramInsights from '@assets/svg/dataSources/instagramInsights.svg';
import meta from '@assets/svg/dataSources/meta.svg';
import tiktok from '@assets/svg/dataSources/tiktok.png';

import { DataSourceIdentifier } from 'common/enums';

import { useBreakpointValue } from '@chakra-ui/react';

const sourceIcons = {
    [DataSourceIdentifier.DV360]: dv360,
    [DataSourceIdentifier.META]: meta,
    [DataSourceIdentifier.GOOGLE_ADS]: googleAds,
    [DataSourceIdentifier.GA4]: googleAnalytics,
    [DataSourceIdentifier.FACEBOOK_INSIGHTS]: facebookInsights,
    [DataSourceIdentifier.INSTAGRAM_INSIGHTS]: instagramInsights,
    [DataSourceIdentifier.GEMIUS_GDE]: gemiusGDE,
    [DataSourceIdentifier.ADFORM]: adform,
    [DataSourceIdentifier.TIKTOK]: tiktok
};

const sourceName = {
    [DataSourceIdentifier.DV360]: 'Display & Video 360',
    [DataSourceIdentifier.META]: 'Meta Ads',
    [DataSourceIdentifier.GOOGLE_ADS]: 'Google Ads',
    [DataSourceIdentifier.GA4]: 'GA4',
    [DataSourceIdentifier.FACEBOOK_INSIGHTS]: 'Facebook Insights',
    [DataSourceIdentifier.INSTAGRAM_INSIGHTS]: 'Instagram Insights',
    [DataSourceIdentifier.GEMIUS_GDE]: 'Gemius Direct Effect',
    [DataSourceIdentifier.ADFORM]: 'Adform',
    [DataSourceIdentifier.TIKTOK]: 'TikTok'
};

export interface SourceIconProps {
    selectedIcons: DataSourceIdentifier[];
    withText?: boolean;
    iconWidth?: string;
    iconHeight?: string;
    asStack?: boolean;
}

const SourceIcon: React.FC<SourceIconProps> = ({
    selectedIcons: sources,
    withText,
    iconHeight,
    iconWidth,
    asStack = false
}) => {
    const iconH = useBreakpointValue({
        xs: '1rem',
        sm: '0.75rem',
        md: '0.75rem',
        lg: '1.75rem',
        xl: '1.75rem'
    });
    const iconW = useBreakpointValue({
        xs: '1rem',
        sm: '0.75rem',
        md: '0.75rem',
        lg: '1.75rem',
        xl: '1.75rem'
    });

    const gap = useBreakpointValue({
        xs: '1px',
        sm: '1px',
        md: '1px',
        lg: '4px',
        xl: '4px'
    });

    // Remove duplicates
    const uniqueSources = Array.from(new Set(sources));

    if (asStack) {
        return (
            <AvatarGroup size="sm" gap={2}>
                {uniqueSources?.map((identifier) => {
                    const SourceIcon = sourceIcons[identifier];
                    if (!SourceIcon) {
                        return null;
                    }
                    return (
                        <Image
                            key={identifier}
                            minH={iconH}
                            minW={iconW}
                            height={iconHeight ?? iconH}
                            width={iconWidth ?? iconW}
                            src={SourceIcon}
                            bg="#F8F8F8"
                            borderRadius="full"
                            p="0.3rem"
                            border="1px solid #E2E8F0"
                        />
                    );
                })}
            </AvatarGroup>
        );
    }

    return (
        <Flex gap={gap} p="0.5rem">
            {uniqueSources?.map((identifier) => {
                const Icon = sourceIcons[identifier];
                if (!Icon) {
                    return null;
                }

                const ImgComponent = (
                    <Tooltip key={`tooltip-${identifier}`} label={sourceName[identifier]}>
                        <Image
                            minH={iconH}
                            minW={iconW}
                            height={iconHeight ?? iconH}
                            width={iconWidth ?? iconW}
                            src={Icon}
                            key={identifier}
                        />
                    </Tooltip>
                );

                if (withText) {
                    return (
                        <Flex
                            key={identifier}
                            direction="row"
                            gap="0.5rem"
                            alignItems="center"
                        >
                            {ImgComponent}
                            <span>{sourceName[identifier]}</span>
                        </Flex>
                    );
                }

                return ImgComponent;
            })}
        </Flex>
    );
};

export default SourceIcon;
