import { Box } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { ActivePeriod } from './DatePicker2';

interface Props extends PropsWithChildren {
    currentActivePeriod: ActivePeriod;
}

export default function DateRangeWrapper({ children, currentActivePeriod }: Props) {
    const hoverColor =
        currentActivePeriod === ActivePeriod.DEFAULT
            ? 'datePicker.primaryRange.100'
            : 'datePicker.secondaryRange.100';

    return (
        <Box
            textAlign="center"
            fontFamily="Inter"
            sx={{
                '.rdrStartEdge': {
                    left: '0px'
                },
                '.rdrEndEdge': {
                    right: '0px'
                },
                '.rdrStartEdge, .rdrDayStartOfWeek .rdrInRange': {
                    borderTopLeftRadius: '6px',
                    borderBottomLeftRadius: '6px',
                    top: '4px',
                    bottom: '4px'
                },
                '.rdrEndEdge, .rdrDayEndOfWeek .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange':
                    {
                        borderTopRightRadius: '6px',
                        borderBottomRightRadius: '6px',
                        top: '4px',
                        bottom: '4px',
                        left: 0
                    },

                '.rdrDayStartOfWeek .rdrEndEdge': {
                    borderTopLeftRadius: '6px',
                    borderBottomLeftRadius: '6px'
                },
                '.rdrDayEndOfMonth .rdrInRange': {
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px'
                },
                '.rdrDayStartOfMonth .rdrInRange': {
                    borderTopLeftRadius: '6px',
                    borderBottomLeftRadius: '6px'
                },
                '.rdrWeekDay': {
                    textTransform: 'capitalize',
                    fontWeight: '400',
                    fontSize: '13px',
                    color: 'datePicker.gray.200'
                },
                '.rdrMonth': {
                    width: '19rem',
                    padding: '0'
                },
                '.rdrMonthAndYearPickers select': {
                    textTransform: 'capitalize',
                    fontWeight: '400',
                    fontSize: '12px',
                    color: 'datePicker.gray.200'
                },
                '.rdrDay': {
                    height: '3em',
                    fontFamily: 'DM Sans'
                },
                '.rdrInRange': {
                    top: '4px',
                    bottom: '4px'
                },
                '.rdrDayHovered .rdrDayNumber': {
                    background: hoverColor,
                    zIndex: '2',
                    color: 'white',
                    top: '4px',
                    bottom: '4px',
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px',
                    borderTopLeftRadius: '6px',
                    borderBottomLeftRadius: '6px'
                },

                '.rdrDayHovered .rdrDayNumber span': {
                    color: 'white !important'
                },
                '.rdrDayNumber span': {
                    color: 'datePicker.gray.400 !important'
                },
                '.rdrDayStartPreview': {
                    top: '4px',
                    bottom: '4px',
                    borderTopLeftRadius: '6px',
                    borderBottomLeftRadius: '6px',
                    left: '0px'
                },
                '.rdrDayInPreview': {
                    top: '4px',
                    bottom: '4px'
                },
                '.rdrDayEndPreview': {
                    top: '4px',
                    bottom: '4px',
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px'
                },
                '.rdrDayEndOfWeek .rdrDayInPreview': {
                    borderTopRightRadius: '6px',
                    borderBottomRightRadius: '6px'
                },
                '.rdrDayStartOfWeek .rdrDayInPreview': {
                    borderTopLeftRadius: '6px',
                    borderBottomLeftRadius: '6px'
                },
                '.rdrDayHovered .rdrDayEndPreview': {
                    zIndex: '1'
                }
            }}
        >
            {children}
        </Box>
    );
}
