import { GetReportPageDataArgs, useGetReportPageDataMutation } from '@api';
import { useAppSelector } from '@hooks';
import { createSelector } from '@reduxjs/toolkit';
import { Component } from 'common/types';
import { format } from 'date-fns';
import { useCallback } from 'react';
import {
    selectActiveReportPage,
    selectAppliedFilters,
    selectDateRangeComponent
} from 'src/redux/features/blueprint/bluePrintSlice';

import { assertIsDefined, getDataComponents } from 'src/templates/blueprint/utils';

export function useFetchReportPageData() {
    const dateRangeFilter = useAppSelector(selectDateRangeComponent);

    const selectMemoStart = createSelector(
        [selectAppliedFilters],
        (filters) => filters?.find((f) => f.id === dateRangeFilter?.id)?.value?.start
    );
    const selectMemoEnd = createSelector(
        [selectAppliedFilters],
        (filters) => filters?.find((f) => f.id === dateRangeFilter?.id)?.value?.end
    );
    const selectMemoCompareStart = createSelector(
        [selectAppliedFilters],
        (filters) =>
            filters?.find((f) => f.id === dateRangeFilter?.id)?.value?.compareStart
    );
    const selectMemoCompareEnd = createSelector(
        [selectAppliedFilters],
        (filters) => filters?.find((f) => f.id === dateRangeFilter?.id)?.value?.compareEnd
    );

    const startDate = useAppSelector((state) => selectMemoStart(state));
    const endDate = useAppSelector((state) => selectMemoEnd(state));
    const compareStartDate = useAppSelector((state) => selectMemoCompareStart(state));
    const compareEndDate = useAppSelector((state) => selectMemoCompareEnd(state));

    const activeReportPage = useAppSelector(selectActiveReportPage);

    const [fetchPageData] = useGetReportPageDataMutation();

    return useCallback(
        (args?: Partial<GetReportPageDataArgs>, components?: Record<string, Component>) => {
            assertIsDefined(activeReportPage);

            const filterValues = args?.filterValues?.map((f: any) => {
                if (f.value?.start && f.value?.end) {
                    return {
                        ...f,
                        value: {
                            start: format(new Date(f.value.start), 'yyyy-MM-dd'),
                            end: format(new Date(f.value.end), 'yyyy-MM-dd'),
                            ...(f.value.compareStart && f.value.compareEnd
                                ? {
                                      compareStart: format(
                                          new Date(f.value.compareStart),
                                          'yyyy-MM-dd'
                                      ),
                                      compareEnd: format(
                                          new Date(f.value.compareEnd),
                                          'yyyy-MM-dd'
                                      )
                                  }
                                : {})
                        },
                    };
                }

                return f;
            }) ?? [
                {
                    id: dateRangeFilter?.id,
                    value: {
                        start: format(startDate, 'yyyy-MM-dd'),
                        end: format(endDate, 'yyyy-MM-dd'),
                        ...(compareStartDate && compareEndDate
                            ? {
                                  compareStart: format(compareStartDate, 'yyyy-MM-dd'),
                                  compareEnd: format(compareEndDate, 'yyyy-MM-dd')
                              }
                            : {})
                    },
                }
            ];

            const dataComponents = getDataComponents(components ?? activeReportPage.components);

            if (dataComponents?.length <= 10) {
                fetchPageData({
                    reportPageId: activeReportPage.id,
                    componentIds: dataComponents.map((c) => c.id),
                    filterValues,
                    isSharedReport: args?.isSharedReport
                });

                return;
            }

            const firstHalf = dataComponents.slice(
                0,
                Math.floor(dataComponents.length / 2)
            );

            const secondHalf = dataComponents.slice(
                Math.floor(dataComponents.length / 2)
            );

            if (firstHalf.length) {
                fetchPageData({
                    reportPageId: activeReportPage.id,
                    componentIds: firstHalf.map((c) => c.id),
                    filterValues,
                    isSharedReport: args?.isSharedReport
                });
            }

            if (secondHalf.length) {
                fetchPageData({
                    reportPageId: activeReportPage.id,
                    componentIds: secondHalf.map((c) => c.id),
                    filterValues,
                    isSharedReport: args?.isSharedReport
                });
            }
        },
        [activeReportPage, dateRangeFilter, startDate, endDate, fetchPageData]
    );
}
