import { deleteProperty } from "dot-prop";
const defaultDateRange = {
    properties: {
        style: {},
        buttonStyle: {
            color: '#2b3674',
            _hover: {
                color: '#2b3674',
                borderColor: 'transparent',
                backgroundColor: 'white'
            },
            borderColor: 'transparent',
            backgroundColor: 'white',
            transition: 'color 300ms, background-color 300ms'
        },
        rangeColors: ['#2884FF', '#FFBC41'],
        pickerStyle: {
            color: 'black'
        },
        omitPickerStyle: {
            color: 'black'
        },
        submitButtonStyle: {
            color: 'white',
            _hover: {
                backgroundColor: '#2b3674'
            },
            backgroundColor: '#2b3674'
        },
        popoverContentStyle: {
            color: 'black',
            borderColor: '#2b3674'
        },
        staticRangeLabelStyle: {
            color: 'black'
        },
        labelStyle: {
            color: 'black',
            fontSize: '16px',
            fontWeight: 400,
            fontFamily: 'Arial'
        },
        label: '',
        text: 'Select Date Range'
    }
};
export const copyDateRangeStyles = (properties) => {
    const _properties = { ...properties };
    deleteProperty(_properties, 'label');
    deleteProperty(_properties, 'text');
    return _properties;
};
export default defaultDateRange;
