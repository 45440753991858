import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

import { useCreateUserMutation, useUpdateUserMutation } from '@api';

import { zodResolver } from '@hookform/resolvers/zod';
import { StyledSelect } from '@tasklogy/zircon-ui-components';
import { reportingoAdminRoles, roleDisplayNames, RoleName } from 'common/roles';
import { userValidator } from 'common/validators';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { User } from 'src/redux/types/api';

interface AdminFormModalProps {
    isOpen: boolean;
    existingUser?: User;
    onClose: () => void;
}

interface AdminFormProps extends Omit<AdminFormModalProps, 'isOpen'> {}

type CreateAdminForm = z.infer<typeof userValidator.create>;

const AdminFormContent: React.FC<AdminFormProps> = ({ onClose, existingUser }) => {
    const [createAdmin, { isLoading }] = useCreateUserMutation();
    const [updateAdmin] = useUpdateUserMutation();

    const {
        control,
        register,
        formState: { errors },
        handleSubmit
    } = useForm<CreateAdminForm>({
        resolver: existingUser
            ? zodResolver(userValidator.update.omit({ id: true }))
            : zodResolver(userValidator.create),
        mode: 'onBlur',
        defaultValues: {
            role: existingUser ? existingUser.role : 'reportingo-admin',
            email: existingUser ? existingUser.email : '',
            firstName: existingUser ? existingUser.firstName : '',
            lastName: existingUser ? existingUser.lastName : '',
            phoneNumber: existingUser ? existingUser.phoneNumber : '',
            password: existingUser ? undefined : ''
        }
    });

    const rolesOptions = useMemo(() => {
        const reportingoAdminRolesOptions = reportingoAdminRoles.map((role) => {
            const roleDisplayName = roleDisplayNames[role];
            return { label: roleDisplayName, value: role };
        });

        return reportingoAdminRolesOptions;
    }, []);

    const handleSubmitForm = handleSubmit(async (values) => {
        if (existingUser) {
            try {
                await updateAdmin({ id: existingUser.id, ...values }).unwrap();
                toast.success('User has been successfully updated.');
                onClose();
            } catch (error) {
                toast.error('An unexpected error occurred while updating a user.');
                console.error(error);
            }
            return;
        }

        try {
            await createAdmin(values).unwrap();
            toast.success('User has been successfully created.');
            onClose();
        } catch (error) {
            if ((error as any)?.status === 409) {
                toast.error('User with this email already exists.');
                return;
            }
            toast.error('An unexpected error occurred while creating a user.');
            console.error(error);
        }
    });

    return (
        <>
            <ModalHeader color="#525A66" fontWeight="bold">
                {existingUser ? 'Edit admin' : 'Create admin'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <form>
                    <Grid
                        templateColumns="repeat(12, 1fr)"
                        templateRows="repeat(3, auto)"
                        gap="1rem"
                    >
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.firstName)}
                                isRequired
                            >
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="text"
                                    {...register('firstName', {
                                        required: true,
                                        maxLength: 20
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.firstName?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.lastName)}
                                isRequired
                            >
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="text"
                                    {...register('lastName')}
                                />
                                <FormErrorMessage>
                                    {errors.lastName?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.phoneNumber)}
                            >
                                <FormLabel>Phone Number</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="tel"
                                    {...register('phoneNumber')}
                                />
                                <FormErrorMessage>
                                    {errors.phoneNumber?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.email)}
                                isRequired
                            >
                                <FormLabel>E-mail</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    type="email"
                                    {...register('email')}
                                />
                                <FormErrorMessage>
                                    {errors.email?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={6}>
                            <FormControl
                                color="#525A66"
                                mb="20px"
                                isInvalid={Boolean(errors.password)}
                                isRequired={existingUser ? false : true}
                            >
                                <FormLabel>Password</FormLabel>
                                <Input
                                    padding="25px 20px"
                                    borderColor="#DFDFDF"
                                    borderRadius="7px"
                                    boxShadow="0 3px 14px rgba(0, 0, 0, 0.08)"
                                    bg="white"
                                    _focusVisible={{
                                        boxShadow: '0 3px 14px rgba(0, 0, 0, 0)'
                                    }}
                                    placeholder=""
                                    type="password"
                                    {...register('password', {
                                        setValueAs: (value) =>
                                            value === '' ? undefined : value
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.password?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={6}>
                            <Controller
                                control={control}
                                name="role"
                                rules={{
                                    required: true
                                }}
                                render={({ field: { value, onChange } }) => {
                                    const roleDisplayName = value
                                        ? roleDisplayNames[value as RoleName]
                                        : '';
                                    return (
                                        <FormControl
                                            color="#525A66"
                                            mb="20px"
                                            sx={{
                                                '.select__value-container': {
                                                    height: '50px'
                                                }
                                            }}
                                            isRequired
                                        >
                                            <FormLabel>Role</FormLabel>
                                            <StyledSelect
                                                options={rolesOptions}
                                                value={{
                                                    label: roleDisplayName,
                                                    value: value
                                                }}
                                                onChange={(value: any) =>
                                                    onChange(value.value)
                                                }
                                                classNamePrefix="select"
                                            />
                                        </FormControl>
                                    );
                                }}
                            />
                        </GridItem>
                    </Grid>
                </form>
            </ModalBody>

            <ModalFooter>
                <Button
                    variant="solid"
                    isLoading={isLoading}
                    onClick={() => {
                        handleSubmitForm();
                    }}
                >
                    {existingUser ? 'Update' : 'Create'}
                </Button>
            </ModalFooter>
        </>
    );
};

const AdminFormModal: React.FC<AdminFormModalProps> = ({
    isOpen,
    onClose,
    existingUser
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay backdropFilter="blur(10px)" backgroundColor="transparent" />
            <ModalContent
                boxShadow="0px 5.56px 29.19px 1.39px rgba(36, 99, 235, 0.05);"
                minWidth="50rem"
            >
                <AdminFormContent onClose={onClose} existingUser={existingUser} />
            </ModalContent>
        </Modal>
    );
};

export default AdminFormModal;
